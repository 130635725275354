const imageIsExist = function(url) {
    return new Promise((resolve, reject) => {
        const image = new Image()

        image.onload = function() { // 图片地址有效
            if (this.complete === true) {
                resolve(image)
            }
        }
        image.onerror = function() { // 图片加载失败
            reject('could not load image')
        }
        image.src = url
    })
}

export const defaultImage = {
    updated(el, binding) {
        let placeholderImage = require('@/assets/images/common/default@256.png') // 默认占位图
        // const placeholderImage = 'http://meigou-mgimg.stor.sinaapp.com/xyxcx/logo.png'
        let defaultImage = el.getAttribute('default-img') // 外部传入的默认占位图
        let errorImage = el.getAttribute('error-img')       // 外部传入的错误占位图

        el.setAttribute('src', defaultImage || placeholderImage)
        // el.setAttribute('class', 'img-objectfix-contain')

        let realImageUrl = binding.value // 获取图片地址（ v-image="cover" 的 cover）
        if (realImageUrl) {
            imageIsExist(realImageUrl)
                .then(() => {
                    el.setAttribute('src', realImageUrl)
                })
                .catch(() => {
                    el.setAttribute('src', errorImage || placeholderImage)
                })
        }
    }

}
