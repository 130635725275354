import { createApp } from 'vue'
import App from './App.vue'
//
const app = createApp(App)

// import store from './store'
import router from './router/Index.js'
import { Button, Dialog, NavBar } from 'vant';
import MiddlewaresTitle from './middlewares/TitleBefore';
import { defaultImage } from "./utils/DefaultImage";
router.beforeEach(MiddlewaresTitle);

router.afterEach((to, from, next) => {
    //百度统计
    setTimeout(()=>{
        var _hmt = _hmt || [];
        (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?9d4d04c2597877224e7a89003bafe155";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    })
})
app.use(router.vueRouter)
app.use(Button)
app.use(Dialog)
app.use(NavBar)



app.directive('image', defaultImage)


app.mount('#app')

//中间件
// import MiddlewaresTitle from './middlewares/TitleBefore';
// import MiddlewaresPermission from './middlewares/Permission';
// router.beforeEach(MiddlewaresTitle);
// router.beforeEach(MiddlewaresPermission);

// #fc5490
// #f20055
// #fbbb39
// #fa94ae
// #fa8b08
// #f71a67
// #d734ff

// 紫：eae7f7
// 黄：fff6e5
// 绿：eef8e4
// 蓝：d0ecf5
// 红：faebe7
