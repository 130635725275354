import Home from '../views/Home.vue'

const defaultSuffixTitle = "";

const baikeRoutes = [
    {
        path: '/baike/category',
        name: 'BaikeCategory',
        meta: {
            title: "经期百科",
            menuTitle: "经期百科",
            keepAlive: true // 需要缓存
        },
        component: () => import('@/views/baike/Category.vue')
    },
    {
        path: '/baike/docDetail',
        name: 'DocDetail',
        meta: {
            title: "百科详情",
            menuTitle: "百科详情",
            keepAlive: false
        },
        component: () => import('@/views/baike/Detail.vue')
    }
]

const productRoutes = [
    {
        path: '/product/index',
        name: 'ProductIndex',
        meta: {
            title: "首页",
            menuTitle: "首页",
            keepAlive: true // 需要缓存
        },
        component: () => import('@/views/product/Index.vue')
    },
    {
        path: '/product/presearch',
        name: 'ProductPreSearch',
        meta: {
            title: "搜索",
            menuTitle: "搜索",
            keepAlive: true // 需要缓存
        },
        component: () => import('@/views/product/PreSearch.vue')
    },
    {
        path: '/product/search',
        name: 'ProductSearch',
        meta: {
            title: "搜索",
            menuTitle: "搜索",
            keepAlive: true // 需要缓存
        },
        component: () => import('@/views/product/Search.vue')
    },
    {
        path: '/product/product',
        name: 'Product',
        meta: {
            title: "产品详情",
            menuTitle: "产品详情",
            keepAlive: true // 需要缓存
        },
        component: () => import('@/views/product/Product.vue')
    },
    {
        path: '/product/makeup',
        name: 'Makeup',
        meta: {
            title: "成分详情",
            menuTitle: "成分详情",
            keepAlive: false // 需要缓存
        },
        component: () => import('@/views/product/Makeup.vue')
    },
    {
        path: '/product/makeup_introduce',
        name: 'MakeupIntroduce',
        meta: {
            title: "看懂成分表",
            menuTitle: "看懂成分表",
            keepAlive: false // 需要缓存
        },
        component: () => import('@/views/product/MakeupIntroduce.vue')
    },
    {
        path: '/product/brand',
        name: 'Brand',
        meta: {
            title: "品牌详情",
            menuTitle: "品牌详情",
            keepAlive: true // 需要缓存
        },
        component: () => import('@/views/product/Brand.vue')
    },
]

const xyappRoutes = [
    {
        path: '/xyapp/yinsizhengce',
        name: 'XyappYinsiZhengCe',
        meta: {
            title: '隐私政策',
            menuTitle: '隐私政策',
        },
        component: () => import('@/views/xyapp/Yinsizhengce.vue')
    },
    {
        path: '/xyapp/yonghuxieyi',
        name: 'XyappYongHuXieYi',
        meta: {
            title: '用户协议',
            menuTitle: '用户协议',
        },
        component: () => import('@/views/xyapp/Yonghuxieyi.vue')
    },
    {
        path: '/xyapp/setting/calendar',
        name: 'XyappSettingCalendar',
        meta: {
            title: '日历设置',
            menuTitle: ''
        },
        component: () => import('@/views/xyapp/setting/Calendar.vue')
    }
]

const homeRoutes = [
    {
        path: '/',  //首页
        component: () => import('@/views/Home.vue'),
        name: 'dashboard',
        meta: {
            title: "控制台",
            menuTitle: "控制台"
        },
        redirect: {
            name: 'Home'
        },
        scrollBehavior() {
            return { x: 0, y: 0 }
        },
        children: [
            {
                path: '/index',
                name: 'Home',
                meta: {
                    title: "首页",
                    menuTitle: "首页"
                },
                component: Home
            }
        ].concat(baikeRoutes, productRoutes, xyappRoutes)
    }
]

// const signRoutes = [
//     {
//         path: '/sign',  //登录注册页
//         component: () => import('@/views/sign/Base.vue'),
//         redirect: {
//             name: 'LoginPassword'
//         },
//         children: [
//             {
//                 path: '/login', //登录页
//                 component: () => import('@/views/sign/Login.vue'),
//                 children: [
//                     {
//                         path: '/login/password',    //密码登录
//                         component: () => import('@/views/sign/LoginByPassword.vue'),
//                         name: 'LoginPassword',
//                         meta: {
//                             title: '账号登录' + defaultSuffixTitle
//                         }
//                     }
//                 ]
//             }
//         ]
//     },
// ]





const permissionRoutes = [

]

const commonRoutes = [
    {   path: '/404',   //404页面
        component: () => import('@/views/error/notFound.vue'),
        name: 'notFound',
        meta: {
            title: '404 ' + defaultSuffixTitle
        }
    },
    {   path: '/403',   //403页面
        component: () => import('@/views/error/forbidden.vue'),
        name: 'forbidden',
        meta: {
            title: '403 ' + defaultSuffixTitle
        }
    },
    {   path: '/500',   //500页面
        component: () => import('@/views/error/serviceError.vue'),
        name: 'serviceError',
        meta: {
            title: '500 ' + defaultSuffixTitle
        }
    },
]

export {homeRoutes, commonRoutes, permissionRoutes}

