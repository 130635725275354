<template>
<!--  <div class="home">-->

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="route.fullPath" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component" :key="route.fullPath" v-if="!$route.meta.keepAlive"/>
    </router-view>

<!--  </div>-->
</template>

<script>
// @ is an alias to /src
import { useRoute } from 'vue-router'

export default {
  name: 'Home',
  setup() {
    const route = useRoute()
    return {
      route
    }
  },
  components: {
  }
}
</script>
