<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  text-align: center;
  color: #323233;
  min-height: 100vh;
}
.main {
  letter-spacing: 1px;
  text-align: left;
  background-color: #f6f6f6;
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-family: Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
}
.iconfont {
  width: 1.2em; height: 1.2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
img[src=""],img:not([src]){
  opacity:0;
}
/* iOS下 search 框被污染问题*/
.van-cell.van-search__field {
  padding: 5px var(--van-padding-xs) 5px 0;
  background-color: transparent;
}

</style>
