// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";

import { homeRoutes, commonRoutes, permissionRoutes } from './Router'

const notFoundRoutes = [
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'notFound' }  //404页面
  },
]

const ALL_ROUTE = commonRoutes.concat(homeRoutes, permissionRoutes).concat(notFoundRoutes)

// Vue.use(VueRouter);
// let vueRouter = new VueRouter({
//     mode: 'history',
//     routes: ALL_ROUTE,
// });

const scrollBehavior = function (to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
  // 这个跟你使用 router.go() 或 router.back() 效果一致
  // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
  if (savedPosition) {
    if(savedPosition) {
      return savedPosition;
    }
  } else {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
}

let vueRouter = createRouter({
  history: createWebHistory(),
  routes: ALL_ROUTE,
  scrollBehavior
})


let _routerBeforeArr = [];
vueRouter.beforeEach(async (to, from, next) => {
  const promiseAll = _routerBeforeArr.map(function (func) {
    return func(to, from, next, router.instance);
  });

  await Promise.all(promiseAll);
  next()
})

let _routerAfterArr = [];
vueRouter.afterEach((to, from) => {
  _routerAfterArr.forEach(function (func) {
    func && func(to, from);
  })
})

class router {
  static instance = null;
  static getInstance() {
    if (!this.instance) {
      this.instance = new router;
    }

    return this.instance;
  }

  constructor() {
    this.vueRouter = vueRouter;
    // this['routerSign'] = this.routerPathMap(signRoutes);
    // this['routerPermission'] = this.routerPathMap(permissionRoutes);
  }

  beforeEach(func) {
    if (!(func instanceof Function)) return;

    _routerBeforeArr.push(func);
  }

  afterEach(func) {
    if (!(func instanceof Function)) return;

    _routerAfterArr.push(func);
  }

  //匹配路由的路径
  //@return  {Array<path:string>}  ret
  routerPathMap(routes) {
    let ret = [];
    routes.forEach(route => {
      if (route.path) {
        ret.push(route.path)
      }

      if (route.children) {
        ret = ret.concat(this.routerPathMap(route.children))
      }
    })

    return ret;
  }
}


export default router.getInstance();
